import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';

import { TrainingCourse } from 'models/TrainingCourse';

import { __, date } from 'helpers/i18n';
import { Match, pathToTrainingCourses } from 'helpers/paths';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { ContentContainer, FetchContainer, PageHeader, Text } from 'components';

import StatusTag from 'scenes/components/StatusTag';

import EditCourseForm from './EditCourseForm';
import StatusButton from './StatusButton';

type RouterProps = RouteComponentProps & {
  match: Match<{
    id: string;
  }>;
};

type Props = RouterProps;

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    course: TrainingCourse;
  };
const Course = ({
  course,
  isFetching,
  hasError,
  refetchData,
}: AfterDataLoaderProps) => {
  const lastUpdateInformation = () => {
    return (
      <Text preset="13s7" color="soften" className="mb-4">
        {__('Updated on %1', date(course.updatedAt, 'LL'))}
      </Text>
    );
  };

  const titleWithStatus = () => {
    return (
      <div className="flex gap-4 items-center">
        <Text>{course.name}</Text>
        <StatusTag status={course.status} className={'font-normal'} />
      </div>
    );
  };

  const action = () => {
    return (
      <StatusButton
        course_id={course.id}
        isPublished={course.status === 'published'}
        refetchData={refetchData}
      />
    );
  };

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        return (
          <Fragment>
            <PageHeader
              title={titleWithStatus()}
              withBackButton
              backButtonProps={{
                target: pathToTrainingCourses(),
                children: __('Back to training courses'),
              }}
              description={lastUpdateInformation()}
              actions={action()}
            />
            <ContentContainer>
              <EditCourseForm course={course} />
            </ContentContainer>
          </Fragment>
        );
      }}
    />
  );
};

export default compose(
  newDataLoader({
    fetch: ({ match }: Props) => {
      return get(`training/courses/${match.params.id}`);
    },
    hydrate: {
      course: {
        organism: {},
      },
    },
  })
)(Course) as React.ComponentType<Props>;
