import React, { Fragment } from 'react';

import { __ } from 'helpers/i18n';

import { ContentContainer, PageHeader, PageTitle } from 'components';

const ManagerDashboard = () => {
  return (
    <Fragment>
      <PageTitle title={[__('Trainings'), __('Team follow-up')]} />
      <PageHeader title={__('Team follow-up')} />

      <ContentContainer>TODO</ContentContainer>
    </Fragment>
  );
};

export default ManagerDashboard;
