import React, { Fragment, ReactElement } from 'react';

import type { EvaluationStatus, User } from 'models';

import { clipArray } from 'helpers/array';
import { n__ } from 'helpers/i18n';

import { Avatar, PlusXLeftAvatar, Tooltip } from 'components';

import {
  AvatarWithStatus,
  AvatarWithStatusIndicator,
} from 'scenes/components/AvatarWithStatus';

import UserAvatar from './UserAvatar';

type Props = {
  users: Array<User>;
  statuses?: Array<EvaluationStatus>;
  maxInlineUsers: number;
  renderNoUser: () => ReactElement;
  testClassName?: string;
  totalUserCount?: number;
};

const AvatarList = ({
  users,
  statuses,
  maxInlineUsers,
  renderNoUser,
  testClassName,
  totalUserCount,
}: Props) => {
  if (users.length === 0) return renderNoUser();

  const maxTooltipUsers = 9;
  const [inlineUsers, remainingInlineUsers] = clipArray(users, maxInlineUsers);
  const [tooltipUsers, remainingUsers] = clipArray(
    remainingInlineUsers,
    maxTooltipUsers
  );
  const avatarContainerStyle = {
    display: 'inline-block',
    marginRight: '5px',
    marginBottom: '5px',
  };
  const remainingUserInlineCount = !!totalUserCount
    ? totalUserCount - maxInlineUsers
    : 0;
  const remainingUserInTooltipCount = !!totalUserCount
    ? remainingUserInlineCount - maxTooltipUsers
    : remainingUsers.length;

  return (
    <Fragment>
      {inlineUsers.map((user, i) => {
        const tooltipContent = statuses ? (
          <AvatarWithStatus user={user} status={statuses[i]} />
        ) : (
          <UserAvatar user={user} withJobTitle />
        );
        const tooltipTarget = statuses ? (
          <AvatarWithStatusIndicator status={statuses[i]} user={user} />
        ) : (
          <Avatar url={user.avatarUrl} />
        );
        return (
          <span
            key={user.id + i}
            style={avatarContainerStyle}
            className={testClassName}
          >
            <Tooltip content={tooltipContent}>{tooltipTarget}</Tooltip>
          </span>
        );
      })}
      {tooltipUsers.length > 0 && (
        <span style={avatarContainerStyle}>
          <PlusXLeftAvatar
            count={remainingUserInlineCount}
            tooltipContent={
              <Fragment>
                {tooltipUsers.map((user, i) => (
                  <div
                    style={{ marginTop: i === 0 ? undefined : 8 }}
                    key={user.id + i}
                  >
                    {statuses ? (
                      <AvatarWithStatus
                        user={user}
                        status={statuses[maxInlineUsers + i]}
                      />
                    ) : (
                      <UserAvatar user={user} withJobTitle />
                    )}
                  </div>
                ))}
                {remainingUserInTooltipCount > 0 && (
                  <div style={{ marginTop: 8 }}>
                    <PlusXLeftAvatar count={remainingUserInTooltipCount}>
                      {n__(
                        '%1 more employee',
                        '%1 more employees',
                        remainingUserInTooltipCount
                      )}
                    </PlusXLeftAvatar>
                  </div>
                )}
              </Fragment>
            }
          />
        </span>
      )}
    </Fragment>
  );
};

export default AvatarList;
