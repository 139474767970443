import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Redirect, Route, Switch } from 'components';

import MatricesList from './MatricesList';

export default function Routes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/`} exact component={MatricesList} />

      <Redirect to={`${path}/`} />
    </Switch>
  );
}
