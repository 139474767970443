import React, { Dispatch, SetStateAction } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { BudgetItem, FundingSource } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { __ } from 'helpers/i18n';

import newDataLoader from 'lib/dataLoader/newDataLoader';
import { del, get, post, put } from 'redux/actions/api';

import { Button, FetchContainer } from 'components';

import BudgetListItem from './BudgetListItem';
import { refetchBudgetStats } from './BudgetSummary';

type Props = {
  periodSlug: string;
  setFundingSourcesCurrencies: Dispatch<SetStateAction<string[]>>;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    fundingSources: Array<FundingSource>;
  };

type AfterConnectProps = AfterDataLoaderProps & {
  updateBudgetItem: (attributes: Partial<BudgetItem>) => Promise<void>;
  createBudgetItem: (attributes: Partial<BudgetItem>) => Promise<void>;
  destroyBudgetItem: (id: string) => Promise<void>;
  createFundingSource: (attributes: Partial<FundingSource>) => Promise<void>;
  refetchStats: () => Promise<void>;
};

const BudgetList = ({
  fundingSources,
  setFundingSourcesCurrencies,
  isFetching,
  hasError,
  refetchData,
  updateBudgetItem,
  createBudgetItem,
  destroyBudgetItem,
  createFundingSource,
  refetchStats,
}: AfterConnectProps) => {
  const [budgetItems, setBudgetItems] = React.useState<Array<BudgetItem>>([]);
  const [budgetItemFormIsActive, setBudgetItemFormIsActive] =
    React.useState(false);
  const [availableFundingSources, setAvailableFundingSources] = React.useState<
    Array<FundingSource>
  >([]);

  React.useEffect(() => {
    if (isFetching || !fundingSources) return;

    setBudgetItems(
      fundingSources.map(source => source.budgetItem).filter(item => !!item)
    );

    setAvailableFundingSources(
      fundingSources.filter(source => !source.budgetItem)
    );

    setFundingSourcesCurrencies(
      fundingSources.map(source => source.budgetItem?.provisionedAmountCurrency)
    );
  }, [fundingSources, isFetching, setFundingSourcesCurrencies]);

  const refetchDataAfterAction = async (action: () => Promise<void>) => {
    await action();

    await refetchData();

    setBudgetItemFormIsActive(false);

    refetchStats();
  };

  const handleBudgetItemUpdate = (attributes: Partial<BudgetItem>) =>
    refetchDataAfterAction(async () => {
      if (attributes.id) {
        let newBudgetItems = [...budgetItems];

        const updatedBudgetItemIndex = budgetItems.findIndex(
          item => item.id === attributes.id
        );

        await updateBudgetItem(attributes);

        newBudgetItems[updatedBudgetItemIndex] = {
          ...newBudgetItems[updatedBudgetItemIndex],
          ...attributes,
        };

        setBudgetItems(newBudgetItems);
      } else {
        await createBudgetItem(attributes);
      }
    });

  const handleBudgetItemDestruction = (budgetItem: BudgetItem) =>
    refetchDataAfterAction(() => destroyBudgetItem(budgetItem.id));

  const handleFundingSourceCreation = (attributes: Partial<FundingSource>) =>
    refetchDataAfterAction(() => createFundingSource(attributes));

  const budgetListItemAttributes = {
    fundingSources: fundingSources,
    availableFundingSources: availableFundingSources,
    onBudgetItemUpdate: handleBudgetItemUpdate,
    onBudgetItemDestroy: handleBudgetItemDestruction,
    onFundingSourceCreate: handleFundingSourceCreation,
  };

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      loadingStyle="overlay"
      render={() => {
        return (
          <React.Fragment>
            {budgetItems.map(budgetItem => (
              <BudgetListItem
                key={budgetItem.id}
                budgetItem={budgetItem}
                {...budgetListItemAttributes}
              />
            ))}
            {budgetItemFormIsActive && (
              <BudgetListItem key={null} {...budgetListItemAttributes} />
            )}
            <Button
              color="secondary"
              testClassName="test-define-funding-source-button"
              onClick={() => setBudgetItemFormIsActive(true)}
            >
              <span>{__('Add a funding source')}</span>
            </Button>
          </React.Fragment>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: Props) => {
  const { periodSlug } = ownProps;

  return {
    createBudgetItem: (params: Partial<BudgetItem>) =>
      dispatch(post(`training/periods/${periodSlug}/budget_items/`, params)),
    updateBudgetItem: (params: Partial<BudgetItem>) =>
      dispatch(
        put(`training/periods/${periodSlug}/budget_items/${params.id}`, params)
      ),
    destroyBudgetItem: (budgetItemId: string) =>
      dispatch(
        del(`training/periods/${periodSlug}/budget_items/${budgetItemId}`)
      ),

    createFundingSource: (params: Partial<FundingSource>) =>
      dispatch(post(`training/funding_sources`, { periodSlug, ...params })),
    refetchStats: () => dispatch(refetchBudgetStats(periodSlug)),
  };
};

export default compose<React.ComponentType<Props>>(
  connect(null, mapDispatchToProps),
  newDataLoader({
    fetch: ({ periodSlug }: Props) =>
      get(`training/periods/${periodSlug}/funding_sources`),
    hydrate: {
      fundingSources: {
        budgetItem: {
          abilities: {},
        },
      },
    },
  })
)(BudgetList);
