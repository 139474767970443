import React from 'react';

import { TrainingOrganism } from 'models/TrainingOrganism';

import { FormErrors, FormInputChangeHandler } from 'helpers/hooks/useForm';
import { __ } from 'helpers/i18n';

import {
  Box,
  Button,
  Field,
  FieldError,
  Input,
  Label,
  PullRight,
  Text,
} from 'components';

import OrganismLogo from 'scenes/trainings/CatalogManagement/Organism/OrganismLogo';

export type TrainingOrganismData = {
  name: string;
  website: string;
  contactName: string;
  contactEmail: string;
};

type Props = {
  trainingOrganism?: TrainingOrganism;
  formValues: Partial<TrainingOrganism>;
  handleInputChange: FormInputChangeHandler<TrainingOrganismData>;
  saveButtonLabel: string;
  isDirty: boolean;
  errors: FormErrors;
  onSave: () => void;
};

const OrganismFormDetails = ({
  trainingOrganism,
  formValues,
  handleInputChange,
  saveButtonLabel,
  isDirty,
  errors,
  onSave,
}: Props) => (
  <Box>
    <Text preset="16bs5.5">{__('Training organization information')}</Text>
    <div className="flex mt-4 gap-4">
      {!!trainingOrganism && <OrganismLogo organism={trainingOrganism} />}
      <div className="flex-grow">
        <Field>
          <Label>{__('Name')}</Label>
          <Input
            value={formValues?.name}
            placeholder={__("Enter organization's name")}
            onChange={(name: string) => {
              handleInputChange({ value: name, name: 'name' });
            }}
          />
          <FieldError>{errors.name}</FieldError>
        </Field>
        <Field>
          <Label>{__('Website')}</Label>
          <Input
            value={formValues?.website || ''}
            placeholder={__("Enter organization's website URL")}
            onChange={(website: string) => {
              handleInputChange({ value: website, name: 'website' });
            }}
          />
          <FieldError>{errors.website}</FieldError>
        </Field>
        <Field>
          <Label>{__('Contact name')}</Label>
          <Input
            value={formValues?.contactName}
            placeholder={__("Enter contact's first and last name")}
            onChange={(contact: string) => {
              handleInputChange({ value: contact, name: 'contactName' });
            }}
          />
          <FieldError>{errors.contactName}</FieldError>
        </Field>
        <Field>
          <Label>{__('Contact email')}</Label>
          <Input
            value={formValues?.contactEmail}
            placeholder={__("Enter contact's email address")}
            onChange={(email: string) => {
              handleInputChange({ value: email, name: 'contactEmail' });
            }}
          />
          <FieldError>{errors.contactEmail}</FieldError>
        </Field>
      </div>
    </div>
    {isDirty && (
      <PullRight>
        <div className="mt-8">
          <Button color="primary" onClick={onSave}>
            {saveButtonLabel}
          </Button>
        </div>
      </PullRight>
    )}
  </Box>
);

export default OrganismFormDetails;
