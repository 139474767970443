import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Route, Link as RouterLink } from 'react-router-dom';

import type { MaterialIconName } from 'components/Icon';
import type { TextPreset } from 'components/text/Text';

import { Icon, Link, Text, Tooltip } from 'components';

type NavigationItemPropsBase = {
  to?: string | undefined | null;
  onClick?: () => void;
  label: string;
  icon?: MaterialIconName;
  testName?: string;
  rightContent?: React.ReactNode;
  labelPreset?: TextPreset;
  isDisabled?: boolean;
  disabledReason?: ReactNode;
};

type ClickableNavigationItemProps = NavigationItemPropsBase & {
  onClick: NonNullable<NavigationItemPropsBase['onClick']>;
  isActive?: boolean;
};

type LinkNavigationItemProps = NavigationItemPropsBase & {
  to: NonNullable<NavigationItemPropsBase['to']>;
  exact?: boolean;
};

export type NavigationItem =
  | ClickableNavigationItemProps
  | LinkNavigationItemProps;

const hasOnClick = (
  item: NavigationItem
): item is ClickableNavigationItemProps => {
  return !!item.onClick;
};

const hasLink = (item: NavigationItem): item is LinkNavigationItemProps => {
  return !!item.to;
};

type Props = {
  items: Array<NavigationItem>;
};

const ClickableNavigationItem = ({
  label,
  icon,
  rightContent,
  testName,
  onClick,
  isActive,
  labelPreset,
  isDisabled,
  disabledReason,
}: ClickableNavigationItemProps) => (
  <Tooltip enabled={isDisabled} key={label} content={disabledReason} isLight>
    <li
      className={classNames({
        'is-active': isActive,
        'is-disabled': isDisabled,
      })}
    >
      {icon && <Icon size="small" name={icon} />}
      <Link
        className={null}
        onClick={onClick}
        testClassName={classNames('test-link', testName)}
      >
        <Text preset={labelPreset}>{label}</Text>
        {rightContent}
      </Link>
    </li>
  </Tooltip>
);

const LinkNavigationItem = ({
  to,
  label,
  icon,
  exact,
  rightContent,
  testName,
  labelPreset,
}: LinkNavigationItemProps) => (
  <Route
    key={label}
    path={to.split('?')[0]}
    exact={!!exact}
    children={({ match: isActive }) => {
      return (
        <li className={isActive ? 'is-active' : ''}>
          <RouterLink
            to={to}
            className={classNames('test-link', testName, {
              active: isActive,
            })}
          >
            {icon && <Icon size="small" name={icon} />}
            <Text preset={labelPreset}>{label}</Text>
            {rightContent}
          </RouterLink>
        </li>
      );
    }}
  />
);

export default function NavigationList({ items }: Props) {
  return (
    <ul>
      {items.map(item => {
        if (hasLink(item) && item.isDisabled) {
          item = {
            ...item,
            onClick: () => {},
            to: undefined,
          };
        }

        if (hasOnClick(item)) {
          return <ClickableNavigationItem {...item} />;
        } else if (hasLink(item)) {
          return <LinkNavigationItem {...item} />;
        } else {
          throw new Error(
            'Navigation item should have either `onClick` or `to`'
          );
        }
      })}
    </ul>
  );
}
