import { getCurrentLocale } from 'helpers/i18n';

/**
 * Convert a decimal number to a localized string.
 *
 * @remarks
 *
 * Only works fot our currently supported locales:
 * 'fr', 'it', 'de', 'nl', 'es' and 'en'
 *
 * @param value - The decimal value
 * @returns The localized decimal value
 */
export const toLocaleDecimal = (value: string) => {
  const locale = getCurrentLocale();

  switch (locale) {
    case 'fr':
    case 'it':
    case 'de':
    case 'nl':
    case 'es':
      return value.replace('.', ',');
    case 'en':
      return value;
    default:
      return value;
  }
};

/**
 * Convert a localized decimal value to a standard decimal value.
 *
 * @remarks
 *
 * Only works fot our currently supported locales:
 * 'fr', 'it', 'de', 'nl', 'es' and 'en'
 *
 * @param value - The localized decimal value
 * @returns The standard decimal value
 */
export const toStandardDecimal = (value: string) => {
  const locale = getCurrentLocale();

  switch (locale) {
    case 'fr':
    case 'it':
    case 'de':
    case 'nl':
    case 'es':
      return value.replace(',', '.');
    case 'en':
      return value;
    default:
      return value;
  }
};
