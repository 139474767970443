import React from 'react';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { post } from 'redux/actions/api';

import { Button, Icon } from 'components';

type Props = {
  course_id: string;
  isPublished: boolean;
  refetchData: () => Promise<void>;
};

const StatusButton = ({ course_id, isPublished, refetchData }: Props) => {
  const dispatch = useAppDispatch();
  const handleStatusChange = async (isPublished: boolean) => {
    await dispatch(
      post(
        'training/courses/bulk_update',
        {
          ids: Array.of(course_id),
          published: isPublished,
        },
        {
          successMessage: __('The status was successfully updated.'),
        }
      )
    );

    return refetchData();
  };

  const hideButton = () => {
    return (
      <Button
        color={'secondary'}
        onClick={() => {
          handleStatusChange(false);
        }}
      >
        <Icon name="visibility_off" className="mr-2" />
        {__('Hide')}
      </Button>
    );
  };
  const publishedButton = () => {
    return (
      <Button
        color={'primary'}
        onClick={() => {
          handleStatusChange(true);
        }}
      >
        <Icon name="check" className="mr-2" />
        {__('Publish')}
      </Button>
    );
  };

  return <div>{isPublished ? hideButton() : publishedButton()}</div>;
};
export default StatusButton;
