import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import can from 'helpers/can';
import { useCurrentOrganization } from 'helpers/hooks';

import { Redirect, Route, Switch } from 'components';

import Course from './Course';
import CreateCourseForm from './Course/CreateCourseForm';
import Courses from './Courses';
import Organism from './Organism';
import CreateOrganismForm from './Organism/CreateOrganismForm';
import Organisms from './Organisms';
import Suggestions from './Suggestions/Routes';

export default function Routes() {
  const { path } = useRouteMatch();

  const organization = useCurrentOrganization();
  const canShowEnabledSugestionsTab = can({
    perform: 'show_enabled_training_suggestions_management_tab',
    on: organization,
  });

  return (
    <Switch>
      <Route path={`${path}/courses`} exact component={Courses} />
      <Route path={`${path}/organisms`} exact component={Organisms} />

      <Route path={`${path}/courses/new`} exact component={CreateCourseForm} />

      <Route
        path={`${path}/organisms/new`}
        exact
        component={CreateOrganismForm}
      />
      <Route path={`${path}/courses/:id`} exact component={Course} />
      <Route path={`${path}/organisms/:id`} exact component={Organism} />

      {canShowEnabledSugestionsTab && (
        <Route path={`${path}/suggestions`} component={Suggestions} />
      )}

      <Redirect to={`${path}/courses`} />
    </Switch>
  );
}
