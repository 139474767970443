import React from 'react';

import type { TrainingSession } from 'models';

import { Box } from 'components';

import ParticipantsTable from './ParticipantsTable';

type Props = {
  session: TrainingSession;
};

const Participants = ({ session }: Props) => {
  return (
    <Box>
      <ParticipantsTable
        session={session}
        defaultPaginationParams={{ sort: { participant_name: 'asc' } }}
        paginationType="url"
      />
    </Box>
  );
};

export default Participants;
