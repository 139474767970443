import React from 'react';

import BudgetCard from './BudgetCard';
import PlanStats from './PlanStats';

type Props = {
  periodSlug: string;
  isValidatedPlan: boolean;
};

const Stats = ({ periodSlug, isValidatedPlan }: Props) => {
  return (
    <div
      className="flex mb-8 gap-6"
      key={`training-${isValidatedPlan ? 'validated' : 'forecast'}-plan-stats`}
    >
      <BudgetCard periodSlug={periodSlug} isValidatedPlan={isValidatedPlan} />
      <PlanStats periodSlug={periodSlug} isValidatedPlan={isValidatedPlan} />
    </div>
  );
};

export default Stats;
