import React, { Fragment, useContext, useEffect } from 'react';

import {
  TrainingPlanForecastStats,
  TrainingPlanStats,
  TrainingPlanValidatedStats,
} from 'models/TrainingPlan';

import compositeKey from 'helpers/compositeKey';
import { __, getCurrentLocale } from 'helpers/i18n';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import FetchContainer from 'components/FetchContainer';
import { Box } from 'components/containers';
import { Text } from 'components/text';

import { DataContext } from '..';

type Props = {
  periodSlug: string;
  isValidatedPlan: boolean;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & { stats: TrainingPlanStats };

const ValidatedStats = ({ stats }: { stats: TrainingPlanValidatedStats }) => (
  <Fragment>
    <Text preset="16bs5.5">{__('Completed sessions')}</Text>
    <div className="flex flex-col items-center mb-2">
      <Text preset="32bs1">{stats.completedSessionCount}</Text>
      {stats.totalSessionCount > 0 && (
        <div>
          <Text preset="16s5.5" className="text-text-soften">
            {__(
              'i.e. %1%',
              <b>
                {Math.round(
                  (stats.completedSessionCount * 100) / stats.totalSessionCount
                ).toLocaleString(getCurrentLocale())}
              </b>
            )}
          </Text>
        </div>
      )}
    </div>
  </Fragment>
);

const ForecastStats = ({ stats }: { stats: TrainingPlanForecastStats }) => (
  <Fragment>
    <Text preset="16bs5.5">{__('Forecast training hours')}</Text>
    <Text className="mb-2" preset="32bs1">
      {stats.forecastTrainingHours.toLocaleString(getCurrentLocale())}
    </Text>
  </Fragment>
);

const PlanStats = ({
  isValidatedPlan,
  stats,
  isFetching,
  hasError,
  refetchData,
}: AfterDataLoaderProps) => {
  const { shouldRefetchStats, setShouldRefetchStats } = useContext(DataContext);

  useEffect(() => {
    if (shouldRefetchStats) {
      refetchData();
      setShouldRefetchStats(false);
    }
  }, [shouldRefetchStats, setShouldRefetchStats, refetchData]);

  return (
    <Box className="flex justify-center px-5 py-3 mb-0 w-[15%] max-w-[300px]">
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        loadingStyle="overlay"
        render={() => (
          <div className="text-center flex flex-col justify-between h-full">
            {!stats ? null : isValidatedPlan ? (
              <ValidatedStats stats={stats as TrainingPlanValidatedStats} />
            ) : (
              <ForecastStats stats={stats as TrainingPlanForecastStats} />
            )}
          </div>
        )}
      />
    </Box>
  );
};

export default newDataLoader({
  fetch: ({ periodSlug, isValidatedPlan }: Props) =>
    get(
      `/training/periods/${periodSlug}/plans/${
        isValidatedPlan ? 'validated' : 'forecast'
      }/stats`
    ),
  hydrate: {
    stats: {},
  },
  cacheKey: ({ periodSlug, isValidatedPlan }: Props) =>
    compositeKey({
      periodSlug,
      viewId: 'trainingPlansStats',
      plan: isValidatedPlan ? 'validated' : 'forecast',
    }),
})(PlanStats);
