import React from 'react';

import { BaseTrainingOrganism } from 'models/TrainingOrganism';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { get } from 'redux/actions/api';

import { Button, DirectUploader, Icon, Label, Text } from 'components';
import ImageUploader from 'components/ImageUploader';

type Props = {
  organism: BaseTrainingOrganism;
};

const OrganismLogo = ({ organism }: Props) => {
  const dispatch = useAppDispatch();
  const imgSrc = organism.logoUrl;

  const onUploadSuccess = async () => {
    return dispatch(get(`training/organisms/${organism.id}`));
  };

  const imageUploaderActions = (
    _errors: unknown,
    onImageUpload: () => void
  ) => {
    return (
      <div className="absolute top-0 right-0 invisible group-hover:visible">
        <Button hasIconOnly isRounded onClick={onImageUpload}>
          <Icon name="edit" />
        </Button>
      </div>
    );
  };

  return (
    <div className="relative group">
      <img alt="logo" src={imgSrc} className="rounded-full h-16 w-16" />
      <DirectUploader
        resourcePath={`training/organisms/${organism.id}`}
        method="PUT"
        attribute="logo"
        model="training_organism"
        onSuccess={onUploadSuccess}
        render={({ uploadFromDataURL }) => (
          <ImageUploader
            onUpload={uploadFromDataURL}
            cropShape="round"
            cropShapeAspect={1}
            minZoom={0.2}
            modalTitle={__('Configuration of the logo')}
            uploadErrorMessage={__(
              'The logo must be in .png .jpeg or .gif format with a maximum size of 5Mo.'
            )}
            imageUploaderRender={imageUploaderActions}
          >
            <div className="mb-4">
              <Label>{__('Import a logo')}</Label>
              <Text style={{ whiteSpace: 'pre-wrap' }} preset="13s7">
                {__(
                  'For an optimal quality the logo must respect the following dimensions: 320 x 320 pixels.\n' +
                    'Accepted formats: .png .jpeg or .gif. 5 Mo maximum.'
                )}
              </Text>
            </div>
          </ImageUploader>
        )}
      />
    </div>
  );
};

export default OrganismLogo;
