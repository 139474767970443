import React, { Fragment } from 'react';

import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';

import { post } from 'redux/actions/api';

import { Button } from 'components/buttons';

type Props = {
  selectedTrainingIds: Set<string>;
  resetSelectedTrainingIds: () => void;
  refetchData: () => Promise<void>;
};

const StatusButtons = ({
  selectedTrainingIds,
  resetSelectedTrainingIds,
  refetchData,
}: Props) => {
  const dispatch = useAppDispatch();

  const successNoticeTranslation = n__(
    'The training course status was successfully updated.',
    'The training courses status were successfully updated.',
    Array.from(selectedTrainingIds).length
  );

  const handleStatusChange = async isPublished => {
    await dispatch(
      post(
        'training/courses/bulk_update',
        {
          ids: Array.from(selectedTrainingIds),
          published: isPublished,
        },
        {
          successMessage: successNoticeTranslation,
        }
      )
    );

    resetSelectedTrainingIds();
    return refetchData();
  };

  return (
    <Fragment>
      <div className="flex gap-4">
        <Button
          color="secondary"
          onClick={() => {
            handleStatusChange(true);
          }}
        >
          {__('Publish')}
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            handleStatusChange(false);
          }}
        >
          {__('Hide')}
        </Button>
      </div>
    </Fragment>
  );
};

export default StatusButtons;
