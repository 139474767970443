import React, { Fragment } from 'react';

import { ContentContainer, Text } from 'components';

import PageHeader from '../components/PageHeader';

const MatricesList = () => {
  return (
    <Fragment>
      <PageHeader />
      <ContentContainer>
        <Text preset="32bs1">🏗️</Text> <Text preset="14bs6">Comming soon</Text>
      </ContentContainer>
    </Fragment>
  );
};

export default MatricesList;
