import React, { useState } from 'react';

import { toLocaleDecimal, toStandardDecimal } from 'helpers/locale';

type Props = {
  value: string;
  onValueChange: (value: string) => void;
  label?: string;
};

/**
 * Remove all characters that are not numbers, dots or commas.
 *
 * @param value - The value to clean
 * @returns The cleaned value
 */
const cleanDecimal = (value: string) => value.replace(/[^0-9.,]/g, '');

const FloatInput = ({ value: valueFromProps, onValueChange, label }: Props) => {
  const [value, setValue] = useState(toLocaleDecimal(valueFromProps));

  return (
    <div className="money-input">
      <input
        className="input"
        type="text"
        inputMode="decimal"
        value={value}
        onChange={e => {
          setValue(cleanDecimal(e.target.value));
        }}
        onBlur={e => {
          if (!!e.target.value) {
            onValueChange(toStandardDecimal(e.target.value));
          }
        }}
      />

      {!!label && <span className="currency-symbol">{label}</span>}
    </div>
  );
};

export default FloatInput;
