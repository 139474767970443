import React from 'react';
import { compose } from 'redux';

import { BaseTrainingCourse, PaginatedCollection } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';
import { pathToTrainingCourse, pathToTrainingOrganism } from 'helpers/paths';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { WithPaginationProps } from 'lib/pagination/types';
import withPagination from 'lib/pagination/withPagination';
import { get } from 'redux/actions/api';

import {
  Box,
  DatatableWrapper,
  EmptyStateWithIcon,
  FetchContainer,
  Link,
  SimpleSelectableTable,
} from 'components';

import StatusTag from 'scenes/components/StatusTag';

import OrganismWithAvatar from '../Organisms/OrganismWithAvatar';
import NoCourseState from './NoCourseState';
import StatusButtons from './StatusButtons';

type Props = {};

type AfterPaginateProps = Props & WithPaginationProps;

type AfterConnectProps = DataLoaderProvidedProps &
  AfterPaginateProps & {
    trainingCoursesCollection: PaginatedCollection<BaseTrainingCourse>;
    isFetching: boolean;
    hasError: boolean;
  };
function CourseListWithPagination({
  page,
  countPerPage,
  queryParams,
  setPreviousPageParams,
  setQueryParams,
  setNextPageParams,
  trainingCoursesCollection,
  isFetching,
  hasError,
  refetchData,
}: AfterConnectProps) {
  const { search, filter } = queryParams || {};

  return (
    <Box>
      <DatatableWrapper
        collectionInfo={
          trainingCoursesCollection ? trainingCoursesCollection : null
        }
        search={search}
        page={page}
        countPerPage={countPerPage}
        getPreviousPage={setPreviousPageParams}
        onQueryParamsChange={setQueryParams}
        getNextPage={setNextPageParams}
        isFetching={isFetching}
        withSearch
        hasError={hasError}
        renderNoResult={() => (
          <EmptyStateWithIcon
            title={__('No training courses match your search')}
            iconName={'school'}
            inBox={false}
          />
        )}
        renderNoRecord={() => <NoCourseState />}
        filter={filter}
        filters={[
          { param: 'all', label: __('All') },
          { param: 'published', label: __('Published') },
          { param: 'unpublished', label: __('Not published') },
        ]}
        totalCountRenderer={totalCount =>
          n__('%1 training course', '%1 training courses', totalCount || 0)
        }
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => (
            <SimpleSelectableTable
              columns={[
                {
                  header: __('Training name'),
                  cell: (course: BaseTrainingCourse) => (
                    <Link to={pathToTrainingCourse(course.id)}>
                      {course.name}
                    </Link>
                  ),
                },
                {
                  header: __('Training Organization'),
                  cell: (course: BaseTrainingCourse) => (
                    <Link to={pathToTrainingOrganism(course.organism.id)}>
                      <OrganismWithAvatar organism={course.organism} />
                    </Link>
                  ),
                },
                {
                  header: __('Status'),
                  cell: (course: BaseTrainingCourse) => (
                    <StatusTag status={course.status} />
                  ),
                },
              ]}
              rows={trainingCoursesCollection?.items || []}
              keyFn={course => course.id}
              renderBulkActions={(selectedRowIds, resetSelectedRowIds) => (
                <StatusButtons
                  selectedTrainingIds={selectedRowIds}
                  resetSelectedTrainingIds={resetSelectedRowIds}
                  refetchData={refetchData}
                />
              )}
              className="mb-4"
            />
          )}
        />
      </DatatableWrapper>
    </Box>
  );
}

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      queryParams: { search, filter },
      page,
      countPerPage,
    }: AfterPaginateProps) =>
      get(`training/courses/`, {
        page,
        countPerPage,
        search,
        filter,
      }),
    hydrate: {
      trainingCoursesCollection: {
        items: {
          organism: {},
        },
      },
    },
    cacheKey: ({ queryParams, page, countPerPage }: AfterPaginateProps) =>
      compositeKey({
        type: 'trainingCourses',
        page,
        countPerPage,
        queryParams,
      }),
  })
)(CourseListWithPagination);
