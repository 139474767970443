import React, { Fragment } from 'react';

import { useAppDispatch } from 'helpers/hooks';
import { useForm } from 'helpers/hooks/useForm';
import { __ } from 'helpers/i18n';
import {
  navigate,
  pathToTrainingOrganism,
  pathToTrainingOrganisms,
} from 'helpers/navigation';

import { post } from 'redux/actions/api';

import {
  ContentContainer,
  PageHeader,
  UnsavedModificationsGuard,
} from 'components';

import OrganismFormDetails, {
  TrainingOrganismData,
} from './OrganismFormDetails';

const CreateOrganismForm = () => {
  const dispatch = useAppDispatch();

  const { values, errors, handleInputChange, handleSubmit, isDirty } =
    useForm<TrainingOrganismData>(
      {
        name: '',
        website: '',
        contactName: '',
        contactEmail: '',
      },
      async values => {
        const response = await dispatch(
          post('training/organisms', values, {
            successMessage: __(
              'The training organization was successfully created.'
            ),
          })
        );

        navigate(pathToTrainingOrganism(response.response.body.data.id));
      }
    );

  return (
    <Fragment>
      <PageHeader
        title={__('New Training Organization')}
        withBackButton
        backButtonProps={{
          target: pathToTrainingOrganisms(),
          children: __('Back to training organizations'),
        }}
      />
      <ContentContainer>
        <OrganismFormDetails
          formValues={values}
          handleInputChange={handleInputChange}
          saveButtonLabel={__('Create')}
          errors={errors}
          isDirty={isDirty}
          onSave={handleSubmit}
        />
      </ContentContainer>
      <UnsavedModificationsGuard isDirty={isDirty} onSubmit={handleSubmit} />
    </Fragment>
  );
};

export default CreateOrganismForm;
